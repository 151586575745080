
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
    ADMIN_EMPLOYEES,
    ADMIN_USERS_STAFF_DATA_CREATE,
    ADMIN_SPECIALIZATIONS_CREATE,
    ADMIN_USERS_CREATE,
} from 'Consts/routes';
import {
    USER_PERMISSION_GROUP_STAFF,
} from 'Consts/userPermissions';
import { USER_ROLE_TRAINER } from 'Consts/userRoles';

import { withVariables } from 'Utils/string';
import { hasPermission } from 'Utils/permissions';
import { parseQueryToObject } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import SpecializationsList from 'Components/admin/specializations/List';
import UserList from 'Components/admin/users/List';
import StaffDataList from 'Components/admin/users/StaffDataList';

export const TAB_LIST = 'list';
export const TAB_STAFF = 'staff';
export const TAB_SPECIALIZATIONS = 'specializations';

export default class AdminEmployees extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,

    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history, profile } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search);

        return (
            <StyledComponent
                className="page-employees"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_EMPLOYEES.path),
                        label: 'Pracownicy',
                    }]}
                >        
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Pracownicy"
                        controls={[{
                            visible: Boolean(!queryObject.tab || queryObject.tab === TAB_LIST),
                            key: 'createEmployee',
                            label: 'Nowy pracownik',
                            onClick: () => history.push(
                                withVariables(ADMIN_USERS_CREATE.path, {}, { role: USER_ROLE_TRAINER})
                            ),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_SPECIALIZATIONS),
                            key: 'createSecialization',
                            label: 'Nowa specjalizacja',
                            onClick: () => history.push(ADMIN_SPECIALIZATIONS_CREATE.path),
                        }, {
                            visible: Boolean(queryObject.tab === TAB_STAFF && hasPermission(profile, USER_PERMISSION_GROUP_STAFF)),
                            key: 'create',
                            label: 'Nowe dane kadrowe',
                            onClick: () => history.push(ADMIN_USERS_STAFF_DATA_CREATE.path),

                        }]}
                        tabs={[{
                            key: TAB_LIST,
                            label: 'Lista pracowników',
                            children: (
                                <UserList
                                    location={location}
                                    history={history}
                                    showEnabledFilter={true}
                                    predefinedQuery={{
                                        role: USER_ROLE_TRAINER,
                                    }}
                                    onMapAdditionals={(element, additionals) => additionals.filter(additional => ['email', 'phone', 'meetUrl'].includes(additional.key))}
                                    onMapFilters={filters => filters.filter(filter => ['search', 'enabled', 'orderBy'].includes(filter.name))}
                                />
                            ),
                        }, {
                            visible: Boolean(hasPermission(profile, USER_PERMISSION_GROUP_STAFF)),
                            key: TAB_STAFF,
                            label: 'Kadry',
                            children: (
                                <StaffDataList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }, {
                            key: TAB_SPECIALIZATIONS,
                            label: 'Lista specjalizacji',
                            children: (
                                <SpecializationsList
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}
