import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_USERS_STAFF_DATA_MANAGE } from 'Consts/routes';

import { parseQueryToObject } from 'Utils/querystring';
import { filterKeys } from 'Utils/object';
import { withVariables } from 'Utils/string';
import { getFormattedDate } from 'Utils/date';

import StyledComponent from 'Components/core/StyledComponent';
import PaginatedList from 'Components/layoutAdmin/panel/PaginatedList';
import PaginatedListElement from 'Components/layoutAdmin/panel/PaginatedListElement';

export default class PanelStaffDataList extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        actions: PropTypes.shape({
            list: PropTypes.func.isRequired,
        }).isRequired,
        staffDataList: PropTypes.shape({
            isLoaded: PropTypes.bool.isRequired,
            elements: PropTypes.array.isRequired,
        }).isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        page: 1,
        search: '',
        orderBy: '',
        timestamp: undefined,
    };

    getQueryConfig = (props = this.props) => {
        const { location } = props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        return filterKeys(
            this.defaultQuery,
            queryObject,
            Object.keys(this.defaultQuery)
        );
    }

    componentDidMount = () => {
        this.loadConversations();
    }

    componentDidUpdate = prevProps => {
        const previousQueryObject = this.getQueryConfig(prevProps);
        const queryObject = this.getQueryConfig();

        if (JSON.stringify(previousQueryObject) !== JSON.stringify(queryObject)) {
            this.loadConversations();
        }
    }

    loadConversations = () => {
        const { actions } = this.props;
        const queryObject = this.getQueryConfig();

        actions.list({ ...queryObject });
    }

    render() {
        const { staffDataList, location, history } = this.props;

        return (
            <StyledComponent
                className="chat-conversations-list"
                styles={require('./styles')}
            >
                <PaginatedList
                    location={location}
                    history={history}
                    collection={staffDataList}
                    title="Kadry"
                    queryConfig={this.getQueryConfig()}
                    onMapElement={element => (
                        <PaginatedListElement
                            key={element.id}
                            title={`${element.user.name} ${element.user.surname}`}
                            additionals={[{
                                name: 'Data zatrudnienia',
                                value: getFormattedDate(element.employedAt, 'date') || 'Brak',
                            }, {
                                name: 'Data zwolnienia',
                                value: getFormattedDate(element.noticeValidTo, 'date') || 'Brak',
                            }]}
                            controls={[{
                                type: 'button',
                                label: 'Zarządzaj',
                                visible: true,
                                to: withVariables(ADMIN_USERS_STAFF_DATA_MANAGE.path, { id: element.user.id }),
                            }]}
                        />
                    )}
                    filters={[
                        {
                            name: 'search',
                            label: 'Szukaj',
                            type: 'text',
                        }, {
                            name: 'orderBy',
                            label: 'Sortuj',
                            type: 'select',
                            inputProps: {
                                options: [{
                                    label: 'Ostatnio utworzeni',
                                    value: '',
                                }, {
                                    label: 'Alfabetycznie',
                                    value: 'alphabetic',
                                }, {
                                    label: 'Ostatnio zatrudnieni',
                                    value: 'newestEmployed',
                                }, {
                                    label: 'Ostatnio zwolnieni',
                                    value: 'latestReleased',
                                }],
                            },
                        },
                    ]}
                />
            </StyledComponent>
        );
    }
}
